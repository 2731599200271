import { gql } from "@apollo/client";

export const GET_PROVIDERS = gql`
  query getProviders($type: String) {
    getProviders(type: $type) {
      insuranceCompany
      isEnabled
      isPartnerProvider
    }
  }
`;

export const GET_PARTNER_FORMS = gql`
  query GetPartnerDetailsForm($nif: String!) {
    getPartnerDetailsForm(nif: $nif) {
      address
      asfNumber
      asfPdf {
        name
        url
      }
      logo {
        name
        url
      }
      colors {
        primary
        secondary
      }
      consents {
        usageConsent
        accuracyConsent
        dataConsent
      }
      additionalAttributes {
        isSameCommercial
        isSameFinancial
      }
      authorized
      cae
      citizenCardPdf {
        name
        url
      }
      city
      adminContact {
        personEmail
        personName
        personPhone
        userId
      }
      commercialContact {
        personEmail
        personName
        personPhone
        userId
      }
      financialContact {
        userId
        personName
        personEmail
        personPhone
      }
      legalContact {
        personEmail
        personName
        personLegalId
        personPhone
        userId
      }
      insurancePolicyNumber
      insurancePolicyRenewalDate
      name
      nif
      particularConditionsPdf {
        name
        url
      }
      legalRepresentativePdf{
        name
        url
        fileUuid
      }
      otherFilesPdf{
        name
        url
        fileUuid
      }
      permanentCertificateNumber
      postalCode
      partnershipType
      verified
      insurers
      otherMediatorAgreement
      whichMediator
      isSignatureComplete
      signatureDeliveryMethod
      volume
      sendEmailDocsAdmin
      worksFor
      uuid
      fontFamily
    }
  }
`;
